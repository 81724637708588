import debounce from 'lodash/debounce';
import React, { useCallback, useEffect } from 'react';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserRead } from '@headway/api/models/UserRead';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { ANTHEM_PATIENT_FACING_CARRIER_CONSOLIDATION } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useSearchableFrontEndCarriersQuery } from '@headway/shared/hooks/useSearchableFrontEndCarriersQuery';
import { trackEvent } from '@headway/shared/utils/analytics';
import { getValidationResult } from '@headway/shared/utils/memberIdValidationUtils';

interface MemberIdValidationGuidanceProps {
  patient: UserRead;
  provider?: ProviderRead;
  memberId: string;
  frontEndCarrierId: number;
  autoSpacing?: boolean;
}

export const MemberIdValidationGuidance: React.FC<
  MemberIdValidationGuidanceProps
> = ({
  patient,
  provider,
  memberId,
  frontEndCarrierId,
  autoSpacing = true,
}) => {
  const { carriersById } = useSearchableFrontEndCarriersQuery(true);

  const anthemPatientFacingCarrierConsolidationEnabled: boolean = useFlag(
    ANTHEM_PATIENT_FACING_CARRIER_CONSOLIDATION,
    false
  );

  const validationResult = React.useMemo(
    () =>
      getValidationResult(
        memberId,
        frontEndCarrierId,
        anthemPatientFacingCarrierConsolidationEnabled
      ),
    [memberId, frontEndCarrierId]
  );
  const trackEventDebounced = useCallback(
    debounce((validationResult) => {
      if (validationResult) {
        trackEvent({
          name: 'Add Insurance Inline Error Surfaced',
          properties: {
            patientUserId: patient.id,
            providerId: provider?.id || null,
            insuranceElement: 'member_id',
            insuranceElementErrorDescription: 'suspected_invalid_format',
            insuranceElementErrorString: [memberId],
            insuranceSubmissionBlocked: false,
          },
        });
      }
    }, 1500),
    [patient.id, provider?.id, memberId]
  );
  useEffect(() => {
    trackEventDebounced(validationResult);

    // Cleanup function to cancel the debounce if component unmounts or dependencies change
    return () => {
      if (trackEventDebounced.cancel) trackEventDebounced.cancel();
    };
  }, [validationResult, trackEventDebounced]);

  if (!validationResult) return null;

  const {
    invalidPrefix,
    invalidSuffix,
    invalidFormat,
    formattedPrefixes,
    formattedSuffixes,
    minLength,
    maxLength,
    characterTypes,
  } = validationResult;

  return (
    <div
      className={autoSpacing ? 'm-4' : undefined}
      data-testid="MemberIdValidationGuidanceCard"
      data-variant="warning"
    >
      <GuidanceCard variant="warning">
        <SubBodyText>
          <div className="font-bold">
            {`It looks like this might not be a valid member id. ${
              carriersById[frontEndCarrierId]?.name ?? 'Member'
            } IDs:`}
          </div>
          {formattedPrefixes && (
            <li className={invalidPrefix ? 'text-bold' : ''}>
              Start with: {formattedPrefixes}
            </li>
          )}
          {formattedSuffixes && (
            <li className={invalidSuffix ? 'text-bold' : ''}>
              End with: {formattedSuffixes}
            </li>
          )}
          <li className={invalidFormat ? 'text-bold' : ''}>
            {minLength === maxLength
              ? `Are ${minLength} characters long`
              : `Are between ${minLength} to ${maxLength} characters long`}{' '}
            and can contain {characterTypes}
          </li>
        </SubBodyText>
      </GuidanceCard>
    </div>
  );
};
